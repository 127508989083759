import React, { useContext } from "react";
import { Input } from "components/Form";
import { validateEmail } from "utils/string";
import ShipmentContext from "../ShipmentContext";
import LogisticsPlan from "./LogisticsPlan";

function Logistics({ shippingTerms, shippingZone }) {
  const { state, dispatch } = useContext(ShipmentContext);

  const validateAdditionalEmails = (string) => {
    if (!!!string) return true;
    const emails = string.replace(/\s/g, "").split(",");
    return emails.filter((i) => i !== "").every((i) => validateEmail(i));
  };

  return (
    <div className="mt-4">
      <div className="mt-4">
        <LogisticsPlan
          shippingTerms={shippingTerms}
          shippingZone={shippingZone}
        />
      </div>

      <div className="lg:flex pb-3 mt-4">
        <div className="w-full">
          <label className="pb-2">Additional Emails(cc):</label>
          <Input
            className={`
               w-full mt-2
               ${validateAdditionalEmails(state.extraEmails) ? "" : "bg-red-200"}
            `}
            type="text"
            value={state.extraEmails}
            onChange={(e) =>
              dispatch({
                type: "changeShipment",
                payload: { extraEmails: e.target.value },
              })
            }
            placeholder="seperate by ,"
          />
        </div>
      </div>
    </div>
  );
}

export default Logistics;
