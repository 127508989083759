import InputSelect from "components/InputSelect";
import { useState } from "react";

const ProductSelector = ({ options, initialValue, onSelect, disabled }) => {
  const [searchText, setSearchText] = useState(initialValue);

  return (
    <InputSelect
      disabled={disabled}
      className="w-full"
      options={options}
      onSelect={(product) => {
        onSelect(product);
        setSearchText(product.name);
      }}
      value={searchText}
      onChange={setSearchText}
      inputProps={{ disabled }}
      renderOption={(product) => (
        <div className="flex justify-between items-center">
          <div className="flex-1">
            <div className="font-semibold text-sm">{product.number}</div>
            <div className="text-xs">{product.originName}</div>
          </div>
          <div className="flex space-x-2 items-center flex-shrink-0">
            {product.images.map((i, index) => (
              <img src={i.url} key={index} className="w-8 h-8 flex-shrink-0" />
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default ProductSelector;
