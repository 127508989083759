import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { FETCH_PRODUCTS_BY_GROUP } from "./graphql";
import CharlesButton from "components/charles/base";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";

const MultiProductsSelectorView = ({ groupId, onCompleted }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_BY_GROUP, {
    variables: { groupId },
  });
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    // when groupId change, clear selected products
    setSelectedProducts([]);
  }, [groupId]);

  if (loading)
    return (
      <div className="relative p-20">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  if (data.products.total === 0)
    return (
      <div className="font-semibold text-red-600">
        <p>No products found in this group. Please choose another group. </p>
        <p>
          If you have any questions, please contact Sharon / Stella about the
          product groups.
        </p>
      </div>
    );

  const sortedProducts = [...data.products.results].sort((a, b) =>
    a.number.localeCompare(b.number),
  );

  function toggleHandler() {
    const allSelected = sortedProducts.every((p) =>
      selectedProducts.includes(p.id),
    );
    if (allSelected) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(sortedProducts.map((p) => p.id));
    }
  }

  return (
    <div className="">
      <div className="max-h-[50vh] overflow-auto bg-white rounded-xl flex flex-col">
        <div>
          <table>
            <tbody>
              {sortedProducts.map((i) => (
                <tr
                  key={i.id}
                  className="border-y border-gray-100 cursor-pointer hover:bg-blue-100"
                  onClick={() =>
                    setSelectedProducts((prev) => {
                      if (prev.includes(i.id)) {
                        return prev.filter((p) => p !== i.id);
                      }
                      return [...prev, i.id];
                    })
                  }
                >
                  <td className="px-4 w-8">
                    <input
                      className="cursor-pointer"
                      type="checkbox"
                      checked={selectedProducts.includes(i.id)}
                      readOnly
                    />
                  </td>
                  <td className="pl-0">
                    <div>
                      <div className="font-semibold">{i.number}</div>
                      <div className="text-xs">{i.name}</div>
                    </div>
                  </td>
                  <td>
                    <div className="flex space-x-2 justify-end flex-shrink-0">
                      {i.images.map((j, index) => (
                        <img
                          src={j.url}
                          key={index}
                          className="w-8 h-8 flex-shrink-0"
                        />
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between p-2 px-4 sticky bottom-0 bg-white border-t">
          <CharlesButton className="text-sm" onClick={toggleHandler}>
            Toggle All
          </CharlesButton>
          <div className=" opacity-70 text-xs">
            {selectedProducts.length} of {sortedProducts.length} products
            selected.
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-between">
        <CharlesButton
          primary
          onClick={() =>
            onCompleted(
              selectedProducts.map((id) =>
                sortedProducts.find((p) => p.id === id),
              ),
            )
          }
        >
          Save
        </CharlesButton>
      </div>
    </div>
  );
};

export default MultiProductsSelectorView;
