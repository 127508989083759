import { useQuery } from "@apollo/client";
import { FETCH_ODOO_OBJECTS } from "graphql/query";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { ODOO_MARGIN_BASE_ON_INVOICES } from "./graphlq";
import { useDebounce } from "use-debounce";
import { useModals } from "ModalProvider";
import InvoiceMarginDetailView from "./InvoiceMarginDetailView";
import { forwardRef, useImperativeHandle } from "react";
import XLSX from "xlsx";
import { Alert } from "components/Toast";
import moment from "moment";
import { useState } from "react";
import CharlesButton from "components/charles/base";
import { RiArrowUpDownLine } from "react-icons/ri";

const OdooMarginTableView = forwardRef(
  ({ searchText, startDate, endDate, filterTotal, excludeCustomer }, ref) => {
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const d1 = moment(startDate).format("YYYY-MM-DD");
    const d2 = moment(endDate).format("YYYY-MM-DD");

    let filters = [
      ["state", "=", "posted"],
      ["journal_id.type", "=", "sale"],
      ["date", ">=", d1],
      ["date", "<=", d2],
    ];

    if (debouncedSearchText !== "") {
      const newFilters = [
        "|",
        "|",
        ["name", "ilike", debouncedSearchText],
        ["ref", "ilike", debouncedSearchText],
        ["partner_id.name", "ilike", debouncedSearchText],
      ];
      filters = [...filters, ...newFilters];
    }
    if (excludeCustomer !== "") {
      const newFilters = ["partner_id", "not ilike", excludeCustomer];
      filters = [...filters, newFilters];
    }

    const { loading, error, data } = useQuery(FETCH_ODOO_OBJECTS, {
      variables: {
        model: "account.move",
        method: "web_search_read",
        filters: JSON.stringify(filters),
        fields: [
          "company_currency_id",
          "made_sequence_hole",
          "date",
          "name",
          "partner_id",
          "ref",
          "amount_total_signed",
          "type_name",
        ],
      },
    });

    if (loading)
      return (
        <div className="p-20">
          <InlineSpinner />
        </div>
      );
    if (error) return <Errors errors={error} />;

    const res = JSON.parse(data.odooQuery);

    const invoices = res.records.map((i) => ({ ...i, number: i.name }));

    return (
      <TableWithMargins
        ref={ref}
        invoices={invoices}
        startDate={d1}
        endDate={d2}
        filterTotal={filterTotal}
      />
    );
  },
);

const TableWithMargins = forwardRef(
  ({ invoices, startDate, endDate, filterTotal }, ref) => {
    const { loading, error, data } = useQuery(ODOO_MARGIN_BASE_ON_INVOICES, {
      variables: { odooInvoiceIds: invoices.map((invoice) => invoice.id) },
    });
    const modal = useModals();
    // Default sort by date, support sort by sales and margin.
    const [sortBy, setSortBy] = useState("date");

    if (error) return <Errors errors={error} />;

    const res = data ? JSON.parse(data.odooMarginBaseOnInvoices) : null;

    const computedInvoices = invoices
      .map((invoice) => {
        if (res) {
          const foundInvoice = res[invoice.id];
          if (foundInvoice) {
            return {
              ...invoice,
              ...foundInvoice,
              number: invoice.name,
            };
          }
          return { ...invoice, total_sales: 0, margin: "0.00%" };
        } else {
          return invoice;
        }
      })
      .filter((i) => {
        if (filterTotal === "NOT_0_TOTAL") {
          return parseInt(i.total_sales) !== 0;
        }
        return true;
      })
      .sort((a, b) => {
        const sortOptions = {
          date: new Date(a.date) - new Date(b.date),
          "-date": new Date(b.date) - new Date(a.date),
          partner: a.partner_id[1].localeCompare(b.partner_id[1]),
          "-partner": b.partner_id[1].localeCompare(a.partner_id[1]),
          sales: b.total_sales - a.total_sales,
          "-sales": a.total_sales - b.total_sales,
          margin: b.margin - a.margin,
          "-margin": a.margin - b.margin,
        };
        return sortOptions[sortBy];
      });

    const totalSales = computedInvoices.reduce(
      (acc, i) => acc + (i.total_sales ?? 0),
      0,
    );

    const totalCredit = computedInvoices.reduce(
      (acc, i) => acc + (i.credit_total ?? 0),
      0,
    );
    const totalDebit = computedInvoices.reduce(
      (acc, i) => acc + (i.debit_total ?? 0),
      0,
    );
    const totalDividedBy = computedInvoices.reduce(
      (acc, i) => acc + (i.divided_by ?? 1),
      0,
    );
    const totalMargin = ((totalCredit - totalDebit) / totalDividedBy) * 100;

    function showInvoice(invoice) {
      const invoiceDetail = res[invoice.id];
      modal.present({
        title: `Invoice ${invoice.number}`,
        subtitle: invoice.partner_id[1],
        children: (
          <InvoiceMarginDetailView invoice={{ ...invoice, ...invoiceDetail }} />
        ),
      });
    }

    useImperativeHandle(ref, () => ({
      download() {
        if (!res) {
          Alert("error", "Please wait for the margin calculation to finish.");
          return;
        }

        const excelData = computedInvoices.map((i) => ({
          Date: i.date,
          Number: i.number,
          Partner: i.partner_id[1],
          Reference: i.ref,
          Type: i.type_name,
          "Sales Total": i.total_sales.toFixed(2),
          "Margin (%)": i.margin.toFixed(2),
        }));
        const sheet = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sheet);
        const filename = `odoo_invoice_margin_${startDate}_${endDate}.xlsx`;

        XLSX.writeFile(wb, filename);
      },
    }));

    return (
      <table>
        <thead>
          <tr className="sticky top-0 bg-gray-50 dark:bg-gray-800">
            <th className="py-3 px-6">
              <div className="flex items-center space-x-2">
                <span>Date</span>
                <CharlesButton
                  onClick={() => {
                    setSortBy(sortBy === "date" ? "-date" : "date");
                  }}
                >
                  <RiArrowUpDownLine />
                </CharlesButton>
              </div>
            </th>
            <th className="px-6">Number</th>
            <th className="px-6">
              <div className="flex items-center space-x-2">
                <span>Partner</span>
                <CharlesButton
                  onClick={() => {
                    setSortBy(sortBy === "partner" ? "-partner" : "partner");
                  }}
                >
                  <RiArrowUpDownLine />
                </CharlesButton>
              </div>
            </th>
            <th className="px-6">Reference</th>
            <th className="px-6 text-right">Type</th>
            <th className="px-6 text-right">
              <div className="flex justify-end items-center space-x-2">
                <CharlesButton
                  onClick={() => {
                    setSortBy(sortBy === "sales" ? "-sales" : "sales");
                  }}
                >
                  <RiArrowUpDownLine />
                </CharlesButton>
                <span>Sales Total</span>
              </div>
            </th>
            <th className="px-6 text-right">
              <div className="flex justify-end items-center space-x-2">
                <InlineSpinner
                  className={loading ? "opacity-100" : "opacity-0"}
                  size={16}
                  text={null}
                />
                <div className="flex justify-end items-center space-x-2">
                  <CharlesButton
                    onClick={() => {
                      setSortBy(sortBy === "margin" ? "-margin" : "margin");
                    }}
                  >
                    <RiArrowUpDownLine />
                  </CharlesButton>
                  <span>Margin</span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className=" divide-y dark:divide-gray-700">
          {computedInvoices.map((invoice, index) => (
            <tr
              key={index}
              className=" hover:bg-blue-50 dark:hover:bg-blue-900 cursor-pointer"
              onClick={() => showInvoice(invoice)}
            >
              <td className="px-6">{invoice.date}</td>
              <td className="px-6">{invoice.number}</td>
              <td className="px-6">{invoice.partner_id[1]}</td>
              <td className="px-6">{invoice.ref}</td>
              <td className="px-6 text-right">{invoice.type_name}</td>
              <td className="px-6 text-right">
                {parseFloat(invoice.total_sales?.toFixed(2)).toLocaleString()}
              </td>
              <td className="px-6 text-right">{invoice.margin?.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="sticky bottom-0 z-10 bg-fuchsia-50 text-base dark:bg-gray-800 font-semibold">
            <td className="px-6 py-3">Total</td>
            <td colSpan="4"></td>
            <td className="px-6 text-right">
              {parseFloat(totalSales.toFixed(2)).toLocaleString()}
            </td>
            <td className="px-6 text-right">{totalMargin.toFixed(2)}%</td>
          </tr>
        </tfoot>
      </table>
    );
  },
);

export default OdooMarginTableView;
