import { gql, useQuery } from "@apollo/client";
import Errors from "./Errors";
import { InlineSpinner } from "./Spinner";

const FETCH_ODOO_STOCK_LOCATIONS = gql`
  query FETCH_ODOO_STOCK_LOCATIONS {
    odooStockLocations(isInternal: true)
  }
`;

const OdooLocationsMultipleSelector = ({ value, onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_ODOO_STOCK_LOCATIONS);

  if (loading)
    return (
      <div className="relative">
        <InlineSpinner text={null} size={14} />
      </div>
    );
  if (error) return <Errors error={error} />;

  const options = JSON.parse(data.odooStockLocations);

  return (
    <div className="flex flex-wrap">
      {options.map((option, index) => (
        <label
          key={index}
          className="flex items-center space-x-2 cursor-pointer mr-2 mb-2"
        >
          <input
            type="checkbox"
            checked={value.includes(option.id)}
            onChange={(e) => {
              if (e.target.checked) {
                onSelect([...value, option.id]);
              } else {
                onSelect(value.filter((v) => v !== option.id));
              }
            }}
          />
          <span>{option.display_name}</span>
        </label>
      ))}
    </div>
  );
};

export default OdooLocationsMultipleSelector;
