import { gql, useMutation } from "@apollo/client";
import { Switcher, Field as InputField, Select } from "components/Form";
import { Alert } from "components/Toast";
import { useContext, useEffect, useState } from "react";
import OdooAttributeInput from "./OdooAttributeInput";
import OdooProductAttributeValues from "./OdooProductAttributeValues";
import OdooProductCategorySelector from "./OdooProductCategorySelector";
import OdooProductTemplateSelector from "./OdooProductTemplateSelector";
import { Button, Tag } from "components/base";
import odooIcon from "assets/odoo-icon.svg";
import Field from "components/Field";
import { productUnitCost } from "pages/price/utils";
import { CurrencyContext } from "CurrencyProvider";
import SupplierInfoView from "./SupplierInfoView";
import { parseError } from "apollo";
import OdooLocationsMultipleSelector from "components/OdooLocationsMultipleSelector";

const CREATE_OPTIONS = ["Create Product", "Create Product Variant"];

const PRODUCT_TYPES = {
  consu: "Consumable",
  product: "Sortable Product",
};

const ROUTES = ["Buy", "Manufacture", "Make to Order"];

export const CREATE_ODOO_PRODUCT = gql`
  mutation CREATE_ODOO_PRODUCT($id: ID!, $input: CreateOdooProductInput) {
    createOdooProduct(id: $id, input: $input) {
      product {
        id
        odooId
      }
    }
  }
`;

const CREATE_ODOO_PRODUCT_VARIANT = gql`
  mutation CREATE_ODOO_PRODUCT_VARIANT(
    $id: ID!
    $input: CreateOdooProductVariantInput
  ) {
    createOdooProductVariant(id: $id, input: $input) {
      product {
        id
        odooId
      }
    }
  }
`;

const SyncErrorHandler = (error) => {
  let message = error.message;
  if (message.includes("A barcode can only be assigned to one product !")) {
    message = "A barcode can only be assigned to one product !";
  } else {
    message = parseError(error);
  }
  Alert("error", message);
};

const OdooSyncerForm = ({ product, onSave }) => {
  const { latestExchangeRate } = useContext(CurrencyContext);
  const [createOdooProduct, createOdooProductRes] = useMutation(
    CREATE_ODOO_PRODUCT,
    {
      onCompleted: (res) => {
        Alert("success", "Sync success.");
        if (onSave) onSave(res.createOdooProduct);
      },
      onError: SyncErrorHandler,
    },
  );
  const [createOdooProductVariant, createOdooProductVariantRes] = useMutation(
    CREATE_ODOO_PRODUCT_VARIANT,
    {
      onCompleted: (res) => {
        Alert("success", "Sync success.");
        if (onSave) onSave(res.createOdooProduct);
      },
      onError: SyncErrorHandler,
    },
  );

  const [createType, setCreateType] = useState(CREATE_OPTIONS[0]);
  const [categId, setCategId] = useState("0");
  const [saleOk, setSaleOk] = useState(product.isSalable);
  const [purchaseOk, setPurchaseOk] = useState(product.isPurchasable);
  const [selectedOdooTemplate, setSelectedOdooTemplate] = useState(null);
  const [newVariant, setNewVariant] = useState("");
  const [name, setName] = useState(product.name);
  const [syncBomWithOdoo, setSyncBomWithOdoo] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [reorderingRuleLocationIds, setReorderingRuleLocationIds] = useState([
    15,
  ]);
  const [productType, setProductType] = useState("product");

  const hasBomProductsNotInOdoo =
    product.children.filter((i) => i.product && i.product.odooId === null)
      .length > 0;

  useEffect(() => {
    if (product) {
      setSaleOk(product.isSalable);
      setPurchaseOk(product.isPurchasable);
      setCategId(product.odooCategoryId || "0");

      if (product.children.length > 0) {
        const bomContainsProductNotInOdoo =
          product.children.filter((i) => i.product && i.product.odooId === null)
            .length > 0;
        setSyncBomWithOdoo(!bomContainsProductNotInOdoo);
        setCreateType("Create Product Variant");
        setRoutes(["Manufacture", "Make to Order"]);
        if (hasBomProductsNotInOdoo) {
          setRoutes(["Buy"]);
          setSyncBomWithOdoo(false);
        }
      } else {
        setCreateType("Create Product");
        setRoutes(["Buy"]);
        setCategId(1);
      }
    }
  }, [product]);

  const number = product.number;
  const barCode = product.barCode;
  const hsCode =
    product.productLine && product.productLine.hsCodeForEu
      ? product.productLine.hsCodeForEu.substring(
          0,
          product.productLine.hsCodeForEu.length - 2,
        )
      : null;
  const weight = product.itemWeight ? product.itemWeight / 1000 : 0;
  const prices = product.prices.filter((i) => i.archivedAt === null);

  const computedCost = productUnitCost(
    product,
    product.defaultQty,
    latestExchangeRate.rate,
  );

  function validateInput(createType) {
    if (categId === "0") {
      Alert("error", "Please select Odoo Product Category.");
      return false;
    }
    if (createType === "Create Product Variant") {
      if (newVariant === "") {
        Alert(
          "error",
          "You need to input a new variant name to create a product variant.",
        );
        return false;
      }
    }
    return true;
  }

  function submitHandler() {
    if (validateInput(createType)) {
      if (createType === "Create Product") {
        createOdooProduct({
          variables: {
            id: product.id,
            input: {
              name,
              number,
              barCode,
              hsCode,
              categId,
              weight,
              productType,
              saleOk,
              purchaseOk,
              routes,
              reorderingRuleLocationIds,
            },
          },
        });
      }
      if (createType === "Create Product Variant") {
        createOdooProductVariant({
          variables: {
            id: product.id,
            input: {
              productTemplateId: selectedOdooTemplate
                ? selectedOdooTemplate.id
                : null,
              newVariant,
              name,
              number,
              barCode,
              hsCode,
              categId,
              weight,
              productType,
              saleOk,
              purchaseOk,
              routes,
              reorderingRuleLocationIds,
              syncBomWithOdoo: hasBomProductsNotInOdoo || syncBomWithOdoo,
              itemsPerSet: product.itemsPerSet,
              bom: hasBomProductsNotInOdoo
                ? []
                : product.children.map((i) => ({
                    productId: i.product.odooId,
                    productQty: i.qty,
                  })),
            },
          },
        });
      }
    }
  }

  return (
    <div className="text-sm">
      <div>
        {product.odooId ? (
          <div className="flex items-center">
            <img
              className="mr-2"
              style={{ height: 16 }}
              src={odooIcon}
              alt="odoo product"
            />
            <div className="font-bold text-purple-600">Connected with Odoo</div>
          </div>
        ) : (
          <b className="opacity-80 text-pink-600">
            This product is NOT connected to any Odoo Product.
          </b>
        )}
      </div>

      <div className="space-y-6 mt-4">
        <section>
          <div className="flex space-x-4 items-baseline">
            <label className="pr-2 font-bold text-gray-600">How to Sync:</label>
            <div>
              <div className="flex space-x-4 ">
                {CREATE_OPTIONS.map((option, index) => (
                  <Tag
                    selected={option === createType}
                    title={option}
                    key={index}
                    onClick={() => setCreateType(option)}
                  />
                ))}
              </div>
              <div className="text-xs opacity-60 px-2 mt-1">
                <p>
                  You typcially choose "Create Product" when you are creating a{" "}
                  <b>component</b>, because it does not has any variants.
                </p>
                <p>
                  When you create a product <b>for Sale</b>, in most case you
                  should choose "Create Procut Variant", either you want to put
                  this variant into an existing product template(which must
                  contain at least 1 varaint), or leave the "Product Template"
                  field empty, just input the "New Variant" with a reasonabl
                  packing name, it will create the template and variant for you.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="space-y-4">
          <OdooProductCategorySelector value={categId} onChange={setCategId} />

          {createType === "Create Product Variant" ? (
            <>
              <OdooProductTemplateSelector
                productTemplate={selectedOdooTemplate}
                onSelect={setSelectedOdooTemplate}
              />
              {selectedOdooTemplate ? (
                <div className="space-y-4">
                  <OdooProductAttributeValues id={selectedOdooTemplate.id} />
                </div>
              ) : null}
              <OdooAttributeInput value={newVariant} onChange={setNewVariant} />
            </>
          ) : null}

          <InputField label="Name" value={name} onChange={setName} />
          <Field label="Number" value={product.number} />
          <Field label="Barcode" value={barCode} />
          <Field
            label="HS Code"
            value={hsCode}
            notes="HS code is fetch from product line `HS Code for EU` without the last two numbers. For example if the hs code is 1234567890, the code would be 12345678. This is a special requirement for EU."
          />
          <Field label="Weight" value={weight} suffix="kg" />

          <div>
            <div className="flex space-x-2 items-center">
              <label className="opacity-60">Prodcut Type</label>
              <div className="flex space-x-4">
                {Object.entries(PRODUCT_TYPES).map(([key, value], index) => (
                  <Tag
                    selected={key === productType}
                    title={value}
                    key={index}
                    onClick={() => setProductType(key)}
                  />
                ))}
              </div>
            </div>

            <div className="text-xs opacity-60 mt-1">
              <p>Labor cost will set this product consumable in Odoo.</p>
              <p>
                1. Consumable的item因为没有re-ordering
                rules，所以不会自动出RFQ。但是如果BOM里面有的话，MO会自己出来。然后会马上显示available，即使没有在PO那里receive。
              </p>
              <p>2. Consumable的item可以在PO那里receive和bill。 </p>
              <p>
                3. Consumable的item不会影响stock value, 不会在
                1400这个account留下交易的痕迹。
              </p>
            </div>
          </div>

          <div className="flex space-x-4 items-center">
            <label className="pr-2 font-bold text-gray-600">Routes: </label>{" "}
            {ROUTES.map((option, index) => (
              <Tag
                selected={routes.includes(option)}
                title={option}
                key={index}
                onClick={() =>
                  setRoutes((prev) =>
                    prev.includes(option)
                      ? prev.filter((x) => x !== option)
                      : [...prev, option],
                  )
                }
              />
            ))}
          </div>

          <div className="flex space-x-4 items-baseline">
            <label className="pr-2 font-bold text-gray-600">
              Reordering Rules:{" "}
            </label>{" "}
            <OdooLocationsMultipleSelector
              value={reorderingRuleLocationIds}
              onSelect={setReorderingRuleLocationIds}
            />
          </div>
        </section>

        <section className="space-x-8 flex">
          <div className="flex items-center space-x-4">
            <label>Sale OK: </label>
            <Switcher
              isOn={saleOk}
              onChange={() => setSaleOk((prev) => !prev)}
            />
          </div>

          <div className="flex items-center space-x-4">
            <label>Purchase OK: </label>
            <Switcher
              isOn={purchaseOk}
              onChange={() => setPurchaseOk((prev) => !prev)}
            />
          </div>
        </section>

        {routes.includes("Buy") && purchaseOk && (
          <SupplierInfoView
            product={product}
            hasBomProductsNotInOdoo={hasBomProductsNotInOdoo}
            prices={prices}
            computedCost={computedCost}
          />
        )}

        {routes.includes("Manufacture") ? (
          <section className="card p-6 px-8 shadow-sm">
            <h4>BOM</h4>

            <Field label="Items / Set" value={product.itemsPerSet} />

            <div className="space-y-2 mt-2">
              {product.children.map((child) => (
                <div key={child.id}>
                  <div className="flex items-center">
                    {child.product.odooId ? (
                      <img
                        className="mr-2"
                        style={{ height: 16 }}
                        src={odooIcon}
                        alt="odoo product"
                      />
                    ) : null}{" "}
                    [{child.product.number}] {child.product.name} &times;{" "}
                    {child.qty}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center space-x-4 mt-4">
              <label>Sync BOM to Odoo: </label>
              <Switcher
                disabled={hasBomProductsNotInOdoo}
                isOn={syncBomWithOdoo}
                onChange={() => setSyncBomWithOdoo((prev) => !prev)}
              />
            </div>

            {hasBomProductsNotInOdoo ? (
              <div className="opacity-60 text-xs mt-1">
                <p>
                  This BOM contains products not in Odoo. In this case, we will
                  not create the BOM in odoo for this product to avoid
                  confusion.{" "}
                </p>
                <p>
                  We have this setup because for example some Acrowork products,
                  we break down the BOM in odoo for cost but in Odoo we don't
                  care.
                </p>
                <p>
                  However, we will create the cost to Odoo for this product
                  variant.
                </p>
              </div>
            ) : null}
          </section>
        ) : null}
      </div>

      <div className="my-8">
        {product.odooId ? (
          <div className="font-bold mt-4 text-orange-600">
            This product is connected with Odoo, syncing data is not support
            right now. We will work on it.
          </div>
        ) : (
          <>
            {createType === "Create Product" ? (
              <Button
                title="Create Product in Odoo"
                size="xl"
                bold
                onClick={submitHandler}
                loading={createOdooProductRes.loading}
                disabled={createOdooProductRes.loading}
              />
            ) : (
              <Button
                title="Create Product Variant in Odoo"
                size="xl"
                bold
                onClick={submitHandler}
                loading={createOdooProductVariantRes.loading}
                disabled={createOdooProductVariantRes.loading}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OdooSyncerForm;
