import { gql } from "@apollo/client";

export const GENERATE_DOCUMENT = gql`
  mutation GENERATE_DOCUMENT(
    $name: String!
    $docType: String!
    $data: String!
  ) {
    generateDocument(name: $name, docType: $docType, data: $data) {
      document {
        id
        name
        docType
        data
      }
    }
  }
`;

export const CREATE_CONTENT_BLOCK = gql`
  mutation CREATE_CONTENT_BLOCK(
    $modelId: ID!
    $modelType: String!
    $contentType: String!
    $content: String!
    $images: [String]
  ) {
    createContentBlock(
      modelId: $modelId
      modelType: $modelType
      contentType: $contentType
      content: $content
      images: $images
    ) {
      contentBlock {
        id
        modelId
        modelType
        contentType
        content
        images
      }
    }
  }
`;

export const CREATE_CONTAINER_COST = gql`
  mutation CREATE_CONTAINER_COST(
    $containerInputs: [ContainerInput!]!
    $zone: String!
  ) {
    createContainerCost(containerInputs: $containerInputs, zone: $zone) {
      containers {
        id
        name
        cost
        cbm
      }
    }
  }
`;
