import SliderArrow from "components/slider/Arrow";
import { useEffect, useState } from "react";
import Slider from "react-slick";

const ImagesPreview = ({ images, initialIndex = 0 }) => {
  const [height, setHeight] = useState(window.innerHeight - 190);

  useEffect(() => {
    function resizeHandler() {
      setHeight(window.innerHeight - 190);
    }

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <Slider
      className="w-full h-full overflow-auto relative md:py-10"
      arrows={true}
      prevArrow={<SliderArrow left />}
      nextArrow={<SliderArrow right />}
      dots={true}
      dotsClass="gallery-slick-dots"
      initialSlide={initialIndex}
    >
      {images.map((i) => (
        <div key={i.id}>
          <div
            className="w-full bg-contain bg-center bg-no-repeat relative flex items-center justify-center"
            style={{ height: `${height}px` }}
          >
            <img className="max-h-full" src={i.url} alt={i.name} />
            <a
              className="absolute right-10 bottom-0 text-blue-600"
              title="Download"
              target="_blank"
              href={i.url}
              download={i.name}
            >
              Download
            </a>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ImagesPreview;
