const odooHost =
  process.env.NODE_ENV == "production" ? "waboba" : "w-test-2023-04-23";

const AppSettings = {
  odooHost,
  odooBaseUrl: `https://${odooHost}.odoo.com`,
  productCategory: "Catalog 2025",
  lifestyleImagesCategory: "2025 Lifestyle Images",
  exchangeRateNote: null,
};

export default AppSettings;
