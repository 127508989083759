import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_OR_UPDATE_INC_RECEIVED_SHIPMENTS } from "./graphql";
import { Alert } from "components/Toast";
import { useState } from "react";
import { NativeDatePicker } from "components/Form";
import moment from "moment";
import CharlesButton from "components/charles/base";

const InboundShipmentsView = ({ shipments, hide }) => {
  const [save, saveRes] = useMutation(CREATE_OR_UPDATE_INC_RECEIVED_SHIPMENTS, {
    onCompleted() {
      Alert("success", "Shipment receive date updated successfully.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["INC_INV_OVERVIEW"],
    awaitRefetchQueries: true,
  });

  const [editedShipments, setEditedShipments] = useState(shipments);

  function onChangeShipment(shipment) {
    const newEditedShipments = editedShipments.map((i) =>
      i.id === shipment.id ? shipment : i,
    );
    setEditedShipments(newEditedShipments);
  }

  function saveHandler() {
    save({
      variables: {
        receivedShipmentList: editedShipments.map((shipment) => ({
          originShipmentId: shipment.id,
          receiveDate: shipment.date,
        })),
      },
    });
  }

  return (
    <div>
      <div className="-mx-2">
        <table>
          <thead>
            <tr>
              <th>Shipment</th>
              <th>Order Reference</th>
              <th className="text-right">Receive Date</th>
              <th className="text-right">QTY</th>
            </tr>
          </thead>
          <tbody>
            {editedShipments.map((shipment, index) => (
              <tr key={index} className="border-y dark:border-gray-700">
                <td>
                  <Link
                    to={`/shipment/shipments/${shipment.id}`}
                    target="_blank"
                  >
                    {shipment.invoiceNumber}
                  </Link>
                </td>
                <td>{shipment.po}</td>
                <td className="text-right">
                  <NativeDatePicker
                    value={shipment.date}
                    onChange={(e) => {
                      const d = moment(e.target.value).format("YYYY-MM-DD");
                      onChangeShipment({ ...shipment, date: d });
                    }}
                  />
                </td>
                <td className="text-right">{shipment.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 text-sm">
        <div className="text-xs opacity-70">
          Receive date is ETA + 14 days if not provided. You could change the
          date if needed.
        </div>
        <div className="mt-4">
          <CharlesButton onClick={saveHandler} loading={saveRes.loading}>
            Save Shipments
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

export default InboundShipmentsView;
