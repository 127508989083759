import { Link, Navigate, Route, Routes } from "react-router-dom";
import PlanDetail from "./PlanDetail";
import PlanList from "./PlanList";
import HandledPlanList from "./HandledPlanList";
import Page from "components/Page";
import LumpSumPage from "./lumpSum";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import { ContainerCostForm } from "components/ContainerizationView";

const OrderSchedulerRoutes = () => {
  return (
    <Routes>
      <Route path="plans" element={<OrderSchedulerIndexPage />} />
      <Route path="plans/handled" element={<HandledPlanList />} />
      <Route path="plans/:id" element={<PlanDetail />} />
      <Route path="plans/handled/:id" element={<PlanDetail />} />
      <Route path="plans/lumpsum/*" element={<LumpSumPage />} />
      <Route path="*" element={<Navigate to="/order-scheduler/plans" />} />
    </Routes>
  );
};

const OrderSchedulerIndexPage = () => {
  const modal = useModals();

  const renderCostUpdate = () => {
    modal.present({
      title: "Shipping Cost Update",
      center: true,
      isBeingPresented: true,
      maxWidth: "max-w-2xl",
      children: <ContainerCostForm hide={modal.hide} />,
    });
  };

  return (
    <Page
      title={
        <div className="flex items-baseline space-x-2">
          <div>Order Scheduler</div>
          <div className="text-base opacity-70 font-normal -translate-y-px">
            v3.1
          </div>
        </div>
      }
      rightButtons={
        <div className="flex space-x-6">
          <CharlesButton onClick={renderCostUpdate}>
            Shipping Cost Update
          </CharlesButton>
          <Link to="handled">Handled Plans</Link>
          <Link to="lumpsum">Lump Sum</Link>
        </div>
      }
    >
      <PlanList />
    </Page>
  );
};

export default OrderSchedulerRoutes;
