import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Select } from "./Form";

const FETCH_ODOO_STOCK_LOCATIONS = gql`
  query FETCH_ODOO_STOCK_LOCATIONS {
    odooStockLocations
  }
`;

const OdooStockLocationSelector = ({
  value,
  onSelect,
  initialValue,
  ...rest
}) => {
  const { loading, error, data } = useQuery(FETCH_ODOO_STOCK_LOCATIONS);

  useEffect(() => {
    if (data && initialValue && value !== initialValue) onSelect(initialValue);
  }, [data]);

  const odooStockLocations = data ? JSON.parse(data.odooStockLocations) : [];

  return (
    <Select
      value={value || "0"}
      onChange={(e) => onSelect(e.target.value)}
      {...rest}
    >
      {loading ? (
        <option value="0" disabled>
          loading...
        </option>
      ) : error ? (
        <option value="0" disabled>
          {error.message}
        </option>
      ) : (
        <>
          <option value="0" disabled>
            --- Select ---
          </option>
          {odooStockLocations.map((i, index) => (
            <option key={index} value={i.id}>
              {i.display_name}
            </option>
          ))}
        </>
      )}
    </Select>
  );
};

export default OdooStockLocationSelector;
