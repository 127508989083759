import { gql } from "@apollo/client";

export const AMZ_STOCK_CATEGORY_SAVE_INBOUND = gql`
  mutation AMZ_STOCK_CATEGORY_SAVE_INBOUND(
    $stockCategoryId: ID!
    $inboundId: ID
    $inboundLines: [InboundLineInput]
    $name: String
    $eta: Date
  ) {
    amzStockCategorySaveInbound(
      stockCategoryId: $stockCategoryId
      inboundId: $inboundId
      inboundLines: $inboundLines
      name: $name
      eta: $eta
    ) {
      stockCategory {
        id
        inbounds {
          id
          name
          eta
          lines {
            id
            stock {
              id
              name
              number
            }
            qty
          }
        }
      }
    }
  }
`;
export const FETCH_WABOBA_AB_SHIPMENTS = gql`
  query FETCH_WABOBA_AB_SHIPMENTS {
    shipments(
      latestStatusIn: [
        "waiting for deposit payment"
        "in production"
        "delivered to port"
        "shipping"
      ]
      options: { q: "waboba ab" }
    ) {
      total
      results {
        id
        status
        invoiceNumber
        eta: latestEtd
        shipmentInvoices {
          id
          shipmentInvoiceRows {
            id
            product {
              id
              odooId
            }
            qty
          }
        }
      }
    }
  }
`;

export const AMZ_DELETE_INBOUND = gql`
  mutation AMZ_DELETE_INBOUND($inboundId: ID!) {
    amzDeleteInbound(inboundId: $inboundId) {
      stockCategory {
        id
        inbounds {
          id
        }
      }
    }
  }
`;

export const AMZ_SAVE_STOCK_CATEGORY = gql`
  mutation AMZ_SAVE_STOCK_CATEGORY($id: ID!, $extraData: String) {
    amzSaveStockCategory(id: $id, extraData: $extraData) {
      stockCategory {
        id
        extraData
      }
    }
  }
`;
