import { gql, useMutation, useQuery } from "@apollo/client";
import { NavigationBar } from "components/base";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import { Input } from "components/Form";
import Page from "components/Page";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useEffect } from "react";
import { useState } from "react";
import { BsTrash } from "react-icons/bs";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

const FETCH_WAREHOUSE = gql`
  query FETCH_WAREHOUSE($id: ID!) {
    warehouse(id: $id) {
      id
      name
      data: inventoryComputingData
      inventory {
        id
        name
        stocks {
          id
          odooId
          number
          name
          latestQty
        }
      }
    }
  }
`;

const SAVE_WAREHOUSE = gql`
  mutation SAVE_WAREHOUSE($id: ID!, $inventoryComputingData: String) {
    saveWarehouse(id: $id, inventoryComputingData: $inventoryComputingData) {
      warehouse {
        id
      }
    }
  }
`;

const WarehouseDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_WAREHOUSE, {
    variables: { id },
  });
  const [saveWarehouse, saveWarehouseRes] = useMutation(SAVE_WAREHOUSE, {
    onCompleted() {
      Alert("success", "Saved.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });
  const [cols, setCols] = useState([]);
  useEffect(() => {
    if (data && data.warehouse.data) setCols(JSON.parse(data.warehouse.data));
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const warehouse = data.warehouse;
  const rows = data.warehouse.inventory.stocks.map((stock) => {
    const forecastedQty =
      stock.latestQty -
      cols.reduce((res, col) => res + (col.cells[stock.odooId] || 0), 0);
    return { ...stock, forecastedQty };
  });

  console.log("rows", rows);
  console.log("cols", cols);

  function addColumn() {
    setCols((prev) => [
      ...prev,
      {
        id: v4(),
        name: "",
        cells: data.warehouse.inventory.stocks.reduce((res, s) => {
          res[s.odooId] = 0;
          return res;
        }, {}),
      },
    ]);
  }

  function deleteCol(id) {
    if (window.confirm("Are you sure to delete this column?")) {
      setCols((prev) => prev.filter((x) => x.id !== id));
    }
  }

  function duplicateCol(col) {
    const index = cols.indexOf(col);
    let newCols = [...cols];
    newCols.splice(index + 1, 0, { ...col, id: v4() });
    setCols(newCols);
  }

  function onChangeCol(id, values) {
    setCols((prev) =>
      prev.map((prevCol) =>
        prevCol.id === id ? { ...prevCol, ...values } : prevCol,
      ),
    );
  }

  function onChangeCell(colId, productOdooId, value) {
    setCols((prev) =>
      prev.map((prevCol) =>
        prevCol.id === colId
          ? {
              ...prevCol,
              cells: { ...prevCol.cells, [productOdooId]: value },
            }
          : prevCol,
      ),
    );
  }

  function save() {
    saveWarehouse({
      variables: { id, inventoryComputingData: JSON.stringify(cols) },
    });
  }

  return (
    <div className="flex-1 flex flex-col overflow-auto">
      <NavigationBar
        title={`Warehouse ${warehouse.name}`}
        rightButtons={
          <div className="flex space-x-6">
            <CharlesButton onClick={addColumn}>+ Column</CharlesButton>
            <CharlesButton onClick={save} loading={saveWarehouseRes.loading}>
              Save
            </CharlesButton>
          </div>
        }
      />
      <div className="whitespace-nowrap overflow-auto bg-white dark:bg-gray-800">
        <table>
          <thead>
            <tr className="sticky top-0 bg-white dark:bg-gray-800 z-10">
              <th className="py-4 sticky top-0 left-0 bg-white dark:bg-gray-800 z-20 px-4">
                Product
              </th>
              <th className="text-right">Latest QTY</th>
              {cols.map((col) => (
                <th key={col.id} className="text-center">
                  <div className="relative">
                    <Input
                      placeholder="Column Name"
                      border=""
                      className="text-center bg-gray-50 dark:bg-gray-700 dark:bg-opacity-50 font-semibold w-full leading-loose"
                      style={{ minWidth: "8rem" }}
                      value={col.name}
                      onChange={(e) =>
                        onChangeCol(col.id, {
                          name: e.target.value,
                        })
                      }
                    />
                    <div className="flex items-center space-x-2 absolute right-2 top-0 bottom-0">
                      <CharlesButton onClick={() => duplicateCol(col)}>
                        <HiOutlineDocumentDuplicate size={14} />
                      </CharlesButton>
                      <CharlesButton danger onClick={() => deleteCol(col.id)}>
                        <BsTrash />
                      </CharlesButton>
                    </div>
                  </div>
                </th>
              ))}
              {/* <th className="text-right">Forecasted QTY</th> */}
            </tr>
          </thead>

          <tbody>
            {rows.map((stock) => (
              <tr
                key={stock.id}
                className="border-b border-gray-100 dark:border-gray-700"
              >
                <td className="sticky left-0 dark:bg-gray-800 px-4 z-0 bg-white">
                  #{stock.odooId} [{stock.number}] {stock.name}
                </td>
                <td className="text-right">{stock.latestQty}</td>
                {cols.map((col, index) => {
                  const cumsum =
                    stock.latestQty -
                    cols
                      .filter((_, colIndex) => colIndex <= index)
                      .reduce(
                        (res, col) => res + (col.cells[stock.odooId] || 0),
                        0,
                      );
                  return (
                    <td key={col.id} className="text-center py-0">
                      <Input
                        border=""
                        className={`text-center dark:bg-opacity-50 w-full leading-loose
                                       ${
                                         cumsum < 0
                                           ? "bg-pink-300 dark:bg-pink-700"
                                           : "bg-gray-50 dark:bg-gray-700"
                                       }
                                    `}
                        style={{ minWidth: "8rem" }}
                        value={col.cells[stock.odooId] || 0}
                        onChange={(e) =>
                          onChangeCell(
                            col.id,
                            stock.odooId,
                            parseInt(e.target.value),
                          )
                        }
                      />
                      {/* <div>{cumsum}</div> */}
                    </td>
                  );
                })}
                {/* <td
                           className={`text-right
                           ${
                              stock.forecastedQty >= 0
                                 ? ''
                                 : 'text-red-600 font-semibold'
                           }
                        `}
                        >
                           {stock.forecastedQty}
                        </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WarehouseDetail;
