import { useQuery } from "@apollo/client";
import { FETCH_PRODUCT_GROUPS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useState } from "react";
import { Select } from "components/Form";
import MultiProductsSelectorView from "./MultiProductsSelectorView";

const ChooseProductsFromGroupView = ({ onCompleted }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_GROUPS);
  const [groupId, setGroupId] = useState("0");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const sortedGroups = [...data.allProductAreas].sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <div className="space-y-6">
      <div className="text-sm">
        Choose a group to select products from. This is useful when you want to
        narrow down the products to choose from a pecific group.
      </div>
      <div className="flex items-center space-x-2">
        <label>Product Group: </label>
        <Select value={groupId} onChange={(e) => setGroupId(e.target.value)}>
          <option value="0" disabled>
            --- Choose ---
          </option>
          {sortedGroups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </Select>
      </div>
      {groupId !== "0" && (
        <MultiProductsSelectorView
          onCompleted={onCompleted}
          groupId={groupId}
        />
      )}
    </div>
  );
};

export default ChooseProductsFromGroupView;
