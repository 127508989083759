import { useQuery } from "@apollo/client";
import PlanLinesTable from "./PlanLinesTable";
import ContainerizationView from "components/ContainerizationView";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { FETCH_PLAN_SUMMARY } from "./graphql";

const CombinedPlanSummary = ({ id, shippingZone }) => {
  const { loading, error, data } = useQuery(FETCH_PLAN_SUMMARY, {
    variables: { id },
  });

  if (loading)
    return (
      <div className="p-10 relative">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  return (
    <div>
      <h5>Plan Summary</h5>
      <div className="opacity-70">Original Plan and additional plans.</div>
      <div className="mt-2 -mx-2">
        <PlanLinesTable plan={data.productionPlan} />
      </div>

      <h5 className="mt-8">Capacity</h5>
      <div className="mt-4">
        <ContainerizationView
          totalCbm={data.productionPlan.finalTotalCbm}
          shippingZone={shippingZone}
        />
      </div>
    </div>
  );
};

export default CombinedPlanSummary;
