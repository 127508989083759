import { useRef, useState } from "react";
import ProductForecastView from "./ProductForecastView";
import { INC_SAVE_INVENTORY_ITEMS } from "./graphql";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";
import {
  IoIosCheckmark,
  IoIosCheckmarkCircle,
  IoIosCheckmarkCircleOutline,
} from "react-icons/io";

const ForecastInput = ({
  category,
  sku,
  yearMonth,
  initialValue,
  onChangeForecast,
}) => {
  const [value, setValue] = useState(initialValue || 0);
  const [showInfo, setShowInfo] = useState(false);
  const inputRef = useRef(null);
  const [showPosition, setShowPosition] = useState("topRight");
  const [savedSuccess, setSavedSuccess] = useState(false);
  const [saveForecast, saveForecastRes] = useMutation(
    INC_SAVE_INVENTORY_ITEMS,
    {
      onError(error) {
        Alert("error", parseError(error));
      },
      onCompleted() {
        setSavedSuccess(true);
        setTimeout(() => setSavedSuccess(false), 2000);
      },
    },
  );
  const debouncedSave = useDebouncedCallback(saveForecast, 700);

  console.log("category", category, yearMonth);

  return (
    <div className="relative -mx-4">
      <div
        className={`absolute top-0 left-0 bottom-0 px-1 text-green-600 flex flex-col items-center justify-center transition-all
        ${savedSuccess ? "opacity-100" : "opacity-0"}
        `}
      >
        <IoIosCheckmark size={27} />
      </div>
      <input
        ref={inputRef}
        className={`appearance-none bg-blue-50 dark:bg-blue-900 py-2 px-4 text-right w-full rounded
          ${
            savedSuccess
              ? "bg-green-50 dark:bg-green-900"
              : "bg-blue-50 dark:bg-blue-900"
          }
          `}
        value={value}
        onChange={(e) => {
          const qty = parseInt(e.target.value);
          setValue(qty);
          onChangeForecast(qty);
          debouncedSave({
            variables: {
              warehouse: category.warehouse,
              date: moment(yearMonth).format("YYYY-MM-DD"),
              type: "forecast",
              items: [{ sku, qty }],
            },
          });
        }}
        onFocus={() => {
          setShowInfo(true);

          // check inputRef element position if it is close to the right of the container then show the info on the left
          const inputRect = inputRef.current.getBoundingClientRect();
          let position = "topRight";
          if (window.innerWidth - inputRect.right < 400) {
            position = "topLeft";
            if (window.innerHeight - inputRect.bottom < 400) {
              position = "bottomLeft";
            }
          } else {
            position = "topRight";
            if (window.innerHeight - inputRect.bottom < 400) {
              position = "bottomRight";
            }
          }
          setShowPosition(position);
        }}
      />
      {showInfo && (
        <>
          <div
            className={`absolute w-72 overflow-auto px-4 py-4 rounded-2xl bg-blue-50 dark:bg-blue-900 border border-blue-300 dark:border-blue-500 z-20 mx-4
            ${
              showPosition === "topRight"
                ? "left-full top-0"
                : showPosition === "topLeft"
                  ? "right-full top-0"
                  : showPosition === "bottomRight"
                    ? "left-full bottom-0"
                    : "right-full bottom-0"
            }
            `}
          >
            <ProductForecastView
              sku={sku}
              yearMonth={yearMonth}
              onSelectNumber={(qty) => {
                setValue(qty);
                onChangeForecast(parseInt(qty));
                setShowInfo(false);
              }}
            />
          </div>
          <div
            className="fixed inset-0 z-10"
            onClick={() => setShowInfo(false)}
          ></div>
        </>
      )}
    </div>
  );
};

export default ForecastInput;
