import React, { useState, useContext, useReducer } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { IoIosCloseCircle } from "react-icons/io";
import { Button, CheckBox, Tag } from "components/base";
import Spinner, { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import OdooProduct from "components/OdooProduct";
import OrderPreviewContext from "./OrderPreviewContext";
import reducer from "./reducer";
import { Input } from "components/Form";
import { Alert } from "components/Toast";
import { FRAGMENT_SHIPMENT_ERROR, FETCH_SHIPMENT_LIST } from "../graphql";
import ProductForm from "pages/manage/products/All/ProductForm";
import { ShipmentErrorWithDetails } from "../shipments/Create";
import Page from "components/Page";
import { useProducts } from "hooks/useProducts";
import { useAllCustomers } from "hooks/useCustomers";
import { useFetchMe } from "hooks/user";
import usePing from "hooks/usePing";
import { useModals } from "ModalProvider";
import { BsExclamationCircleFill } from "react-icons/bs";
import CharlesButton from "components/charles/base";
import ContainerizationView, {
  shippingZones,
} from "components/ContainerizationView";

const FETCH_ODOO_SO_DETAIL = gql`
  query FETCH_ODOO_SO_DETAIL($ids: [Int]!) {
    odooSoDetail(ids: $ids) {
      id
      so: name
      po: clientOrderRef
      partnerId
      partnerName
      address
      deliveryAddress
      paymentTerm
      requestCargoReadyDate: xDoMinDate
      shippedBy: xDeliverymethod
      state
      orderLines {
        id
        name
        productId
        qty: productUomQty
      }
    }
  }
`;

const FETCH_INTERNAL_TRANSFER_DETAIL = gql`
  query FETCH_INTERNAL_TRANSFER_DETAIL($ids: [Int]!) {
    odooInternalTransferDetail(ids: $ids) {
      id
      name
      partnerId
      partnerName
      orderLines: moves {
        id
        name
        productId
        qty
      }
    }
  }
`;

const CREATE_SHIPMENT_FROM_ODOO = gql`
  mutation CREATE_SHIPMENT_FROM_ODOO(
    $customerId: ID!
    $invoices: [ShipmentInvoiceFromOdooInputType]!
    $paymentTerms: String
    $deliveryAddressId: ID
  ) {
    createShipmentFromOdoo(
      customerId: $customerId
      invoices: $invoices
      paymentTerms: $paymentTerms
      deliveryAddressId: $deliveryAddressId
    ) {
      shipment {
        id
      }
    }
  }
`;

const FETCH_CUSTOMER = gql`
  query FETCH_CUSTOMER($id: ID!) {
    customer(id: $id) {
      id
      name
      notes
      areas {
        id
        name
      }
      shipmentErrors {
        ...shipmentError
      }
    }
  }
  ${FRAGMENT_SHIPMENT_ERROR}
`;

const Preview = () => {
  const location = useLocation();

  const ids = queryString.parse(location.search).ids
    ? queryString.parse(location.search).ids.split(",")
    : null;
  const type = queryString.parse(location.search).type;
  const deliveryAddressId = queryString.parse(
    location.search,
  ).deliveryAddressId;

  const query =
    type === "so" ? FETCH_ODOO_SO_DETAIL : FETCH_INTERNAL_TRANSFER_DETAIL;
  const { loading, error, data } = useQuery(query, {
    variables: { ids },
    skip: ids === null,
  });
  const productsQuery = useProducts();
  const customersQuery = useAllCustomers();

  const navigate = useNavigate();
  if (!ids) navigate("/shipment/odoo");
  if (loading || productsQuery.loading || customersQuery.loading)
    return <Spinner text="Loading Orders" />;
  if (error) return <Errors error={error} />;
  if (productsQuery.error) return <Errors error={productsQuery.error} />;
  if (customersQuery.error) return <Errors error={customersQuery.error} />;

  return (
    <Page title="Preview Shipment from Odoo" backTo="/shipment/odoo/create">
      <PreviewContent
        data={data}
        type={type}
        allProducts={productsQuery.allProducts}
        customers={customersQuery.customers}
        deliveryAddressId={deliveryAddressId}
      />
    </Page>
  );
};

const PreviewContent = ({
  data,
  type,
  allProducts,
  customers,
  deliveryAddressId,
}) => {
  const navigate = useNavigate();

  const mainData =
    type === "so" ? data.odooSoDetail[0] : data.odooInternalTransferDetail[0];

  const [state, dispatch] = useReducer(reducer, {
    selectedCustomer: customers.find(
      (i) => i.odooPartnerId === mainData.partnerId,
    ),
    customers,
    invoices:
      type === "so"
        ? data.odooSoDetail.map((i) => {
            const po = i.po && i.po !== "false" ? i.po : "";
            return {
              ...i,
              orderLines: i.orderLines
                .filter((i) => i.productId !== null)
                .map((i) => ({
                  ...i,
                  po,
                  selectedProduct: allProducts.find(
                    (p) =>
                      p.odooId === i.productId &&
                      isProductDataReadyForShipment(p),
                  ),
                })),
              number: i.so.replace("SO", ""),
              pos: [po],
            };
          })
        : data.odooInternalTransferDetail.map((i) => {
            const po = "";
            return {
              ...i,
              orderLines: i.orderLines
                .filter((i) => i.productId !== null)
                .map((i) => ({
                  ...i,
                  po,
                  selectedProduct: allProducts.find(
                    (p) =>
                      p.odooId === i.productId &&
                      isProductDataReadyForShipment(p),
                  ),
                })),
              number: i.name.split("/").pop(),
              pos: [po],
            };
          }),
  });
  const [createShipmentFromOdoo, createShipmentFromOdooRes] = useMutation(
    CREATE_SHIPMENT_FROM_ODOO,
    {
      onCompleted: (data) => {
        Alert("success", "Shipment Created.");
        navigate(
          `/shipment/shipments/${data.createShipmentFromOdoo.shipment.id}`,
        );
      },
      onError: (error) => Alert("error", error.message),
      refetchQueries: [{ query: FETCH_SHIPMENT_LIST }],
    },
  );

  if (state.length === 0) return <div>No Orders</div>;

  const totalCbm = state.invoices
    .flatMap((i) => i.orderLines)
    .filter((i) => !!i.selectedProduct)
    .reduce((prev, row) => {
      const carton = row.selectedProduct.quantityPerCarton
        ? row.qty / row.selectedProduct.quantityPerCarton
        : 0;
      return (prev += carton * row.selectedProduct.outerCartonCbm);
    }, 0);

  function create() {
    const variables = {
      customerId: state.selectedCustomer.id,
      invoices: state.invoices.map((invoice) => ({
        odooModelId: invoice.id,
        odooModelType: type,
        so: invoice.number,
        pos: invoice.pos,
        rows: invoice.orderLines
          .filter((line) => !!line.selectedProduct)
          .map((line) => ({
            productId: line.selectedProduct.id,
            productOdooId: line.productId,
            qty: line.qty,
            po: line.po,
          })),
      })),
      paymentTerms: mainData.paymentTerm,
      deliveryAddressId,
    };
    createShipmentFromOdoo({ variables });
  }

  // default to US if no EU area
  const shippingZone =
    state.customer.areas.find((area) => shippingZones.includes(area.name))
      ?.name ?? shippingZones[0];

  return (
    <OrderPreviewContext.Provider value={{ allProducts, state, dispatch }}>
      <div className="p-6">
        {process.env.NODE_ENV === "development" ? null : (
          <CustomerImportantInfo customer={state.selectedCustomer} />
        )}

        {state.selectedCustomer ? (
          <>
            <SOProducts />

            <div className="lg:flex items-center bg-red-500 dark:bg-red-800 text-white text-base rounded-2xl mt-6 px-4 lg:px-6 py-2">
              <div className="lg:p-2 font-bold border-red-500 w-48">
                Total CBM
              </div>
              <div className="lg:flex flex-1 items-center">
                <div className="flex-1 font-bold lg:text-right">
                  {parseFloat(totalCbm).toFixed(3)}
                </div>
              </div>
            </div>

            <div className="mt-8 p-8 bg-white dark:bg-gray-800 rounded-2xl">
              <h3 className="mb-4">Container Capacity</h3>
              林向远！！！
              <ContainerizationView
                totalCbm={totalCbm}
                shippingZone={shippingZone}
              />
            </div>

            <div className="py-8 px-2">
              <CharlesButton
                next
                className="text-xl"
                disabled={createShipmentFromOdooRes.loading}
                loading={createShipmentFromOdooRes.loading}
                onClick={create}
              >
                Create Shipment
              </CharlesButton>
            </div>
          </>
        ) : null}
      </div>
    </OrderPreviewContext.Provider>
  );
};

const CustomerImportantInfo = ({ customer }) => {
  const { loading, error, data } = useQuery(FETCH_CUSTOMER, {
    variables: { id: customer.id },
  });
  if (loading)
    return (
      <div className="flex py-4">
        <InlineSpinner size={21} text={null} />
      </div>
    );
  if (error) return <Errors error={error} />;

  if (!data.customer.notes && data.customer.shipmentErrors.length === 0)
    return (
      <div className="border-b pb-4">
        <h3>Customer: {data.customer.name}</h3>
      </div>
    );

  return (
    <div className="card">
      <div className="opacity-70 text-sm">Customer</div>
      <h4 className="mt-1">{data.customer.name}</h4>

      <div className="mt-2 text-lg text-orange-600">
        <BsExclamationCircleFill className="inline-block mr-2 -translate-y-[2px]" />
        Please read this notes and review the errors for this customer before
        you create shipment.
      </div>

      <div className="mt-4">
        {data.customer.notes ? (
          <div className="whitespace-pre-wrap text-base">
            {data.customer.notes}
          </div>
        ) : (
          <label>This customer has no special notes.</label>
        )}
      </div>

      {data.customer.shipmentErrors.length > 0 ? (
        <>
          <hr />
          <div>
            <h4>Customer History Errors</h4>
            <div className="mt-2 whitespace-pre-wrap">
              {data.customer.shipmentErrors.map((e, index) => (
                <div
                  key={index}
                  className={`${index === 0 ? "" : "border-t"} dark:border-gray-700 py-4`}
                >
                  <ShipmentErrorWithDetails shipmentStatusError={e} />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const isProductDataReadyForShipment = (product) => {
  return (
    product &&
    product.ctnNetWeight &&
    product.quantityPerCarton &&
    product.ctnGrossWeight &&
    product.outerCartonCbm &&
    product.productLine
  );
};
const ProductRow = ({ className = "", pos, row, soIndex, rowIndex }) => {
  const {
    data: { me },
  } = useFetchMe();
  const { allProducts, dispatch } = useContext(OrderPreviewContext);
  const [ping, { loading }] = usePing({
    onCompleted: (res) => Alert("success", res.pingForHelp.result),
    onError: (error) => Alert("error", error.message),
  });

  const editModal = useModals();

  const validPos = pos.filter((po) => po !== "");

  const wisProduct = allProducts.find((i) => i.odooId === row.productId);

  function pingForHelp() {
    const message = `ODOO Product #${row.productId} ${row.name} is not found in WIS.

         Page: ${window.location.href}

         -- sent by ${me.email}
      `;
    ping({ variables: { message } });
  }

  return (
    <div className={className}>
      {validPos.length > 0 && (
        <div className="flex items-center mb-2">
          <label className="mr-4">PO: </label>
          {validPos.map((po, index) => (
            <Tag
              className="mr-4"
              size="sm"
              title={po}
              key={index}
              selected={po === row.po}
              onClick={() => {
                dispatch({
                  type: "selectRowPo",
                  payload: { po, soIndex, rowIndex },
                });
              }}
            />
          ))}
        </div>
      )}

      <div className="flex items-center justify-between">
        {wisProduct ? (
          <div className="flex space-x-4 items-center flex-1">
            <CheckBox
              checked={row.selectedProduct}
              onChange={() => {
                dispatch({
                  type: "selectProductInRow",
                  payload: {
                    product: wisProduct,
                    soIndex,
                    rowIndex,
                  },
                });
              }}
            />
            <div className="space-y-1">
              <OdooProduct name={row.name} productId={row.productId} />
              {wisProduct ? (
                <>
                  <div className="flex items-center space-x-2">
                    <span>
                      WIS: [ {wisProduct.number} ] {wisProduct.name}
                    </span>
                    <CharlesButton
                      onClick={() =>
                        editModal.present({
                          title: "Edit Product",
                          fullscreen: true,
                          children: (
                            <ProductForm
                              id={wisProduct.id}
                              hide={editModal.hide}
                            />
                          ),
                        })
                      }
                    >
                      Edit
                    </CharlesButton>
                  </div>
                  {isProductDataReadyForShipment(wisProduct) ? null : (
                    <div className="text-red-600 mt-2 font-semibold">
                      This product does not have enough shipment data, it should
                      has `product line`, `qty / carton`, `ctn net weight`, `ctn
                      gross weight`, these data will help compute the total of
                      shipment like container capacity, total CBM, etc. Make
                      sure you are clear about the product before you create
                      shipment.
                    </div>
                  )}
                </>
              ) : (
                <label>
                  This product from odoo is not in WIS at this moment.
                </label>
              )}
            </div>
          </div>
        ) : (
          <div>
            <OdooProduct name={row.name} productId={row.productId} />
            <div className="flex space-x-4">
              <label className="text-pink-600">
                - This product is not connected correctlly between WIS and ODOO.
              </label>
              <Button
                title="Ping Charlie and Stella"
                onClick={pingForHelp}
                loading={loading}
                disabled={loading}
              />
            </div>
          </div>
        )}

        <div>
          <label className="mr-4">qty:</label>
          <Input
            className="text-right"
            value={row.qty}
            onChange={(e) =>
              dispatch({
                type: "updateRowQty",
                payload: { qty: e.target.value, soIndex, rowIndex },
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

const SOProducts = () => {
  const {
    state: { invoices },
  } = useContext(OrderPreviewContext);

  return (
    <div className="mt-6">
      <div>
        <h3>Products</h3>
        <div className="mt-2 text-xs opacity-70">
          WIS automactically detect and suggest the products base on the product
          connection with Odoo.
          <br /> Check and udpate them if necessary. You could update the qty or
          select part of the products from this SO before creating new shipment
          with this data.
        </div>
      </div>
      <div className="space-y-8">
        {invoices.map((so, soIndex) => (
          <div key={soIndex} className="mt-4 card">
            <InvoiceRow so={so} soIndex={soIndex} />
            <div className="mt-4">
              {so.orderLines.map((row, rowIndex) => (
                <ProductRow
                  className={`py-4 dark:border-gray-700 border-t border-gray-100
                              ${so.orderLines.length !== rowIndex + 1 ? "border-t" : ""}`}
                  pos={so.pos}
                  row={row}
                  key={rowIndex}
                  soIndex={soIndex}
                  rowIndex={rowIndex}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const InvoiceRow = ({ so, soIndex }) => {
  const [justAddedPo, setJustAddedPo] = useState(false);
  const { dispatch } = useContext(OrderPreviewContext);
  return (
    <div className="">
      <div className="flex items-baseline text-sm">
        <label className="w-8 mr-2">SO: </label>
        <Input
          className="flex-1"
          value={so.number}
          onChange={(e) =>
            dispatch({
              type: "updateInvoiceNumber",
              payload: { number: e.target.value, soIndex },
            })
          }
        />
      </div>
      <div className="flex items-baseline mt-2 text-sm">
        <label className="w-8 mr-2">PO: </label>
        <div className="">
          <div className="flex space-x-4">
            {so.pos.map((po, poIndex) => (
              <div className="mb-2 flex flex-1 items-center" key={poIndex}>
                <Input
                  className="flex-1"
                  placeholder="optional"
                  autoFocus={justAddedPo}
                  value={po}
                  onChange={(e) =>
                    dispatch({
                      type: "updateInvoicePo",
                      payload: {
                        lastPo: po,
                        po: e.target.value,
                        soIndex,
                        poIndex,
                      },
                    })
                  }
                />
                {so.pos.length > 1 ? (
                  <div
                    className="text-red-500 cursor-pointer hover:text-red-600 ml-2"
                    onClick={() =>
                      dispatch({
                        type: "removeInvoicePo",
                        payload: { po, soIndex, poIndex },
                      })
                    }
                  >
                    <IoIosCloseCircle />
                  </div>
                ) : null}
              </div>
            ))}
          </div>

          <div className="flex items-center">
            <CharlesButton
              onClick={() => {
                dispatch({
                  type: "addInvoicePo",
                  payload: { po: "", soIndex },
                });
                setJustAddedPo(true);
              }}
            >
              + Add PO
            </CharlesButton>
            <label className="opacity-70 ml-4 text-xs">
              Change SO or PO if needed.
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
