import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const SliderArrow = (props) => {
  console.log("SliderArrow", props);
  const { className, left, right, onClick } = props;
  const disabled = className.includes("slick-disabled");
  return (
    <div
      className={`absolute h-full flex flex-col justify-center px-6
        ${right ? "right-0 top-0 bottom-0" : ""}
        `}
    >
      <div
        className={`z-10 cursor-pointer rounded-full bg-gray-100 hover:bg-gray-200 bg-opacity-70 backdrop-blur w-16 h-16 flex items-center justify-center -translate-y-10
          ${
            disabled
              ? "opacity-30 cursor-not-allowed"
              : "hover:bg-opacity-80 active:bg-opacity-100"
          }
          `}
        onClick={onClick}
        disabled={className.includes("slick-disabled")}
      >
        {left ? (
          <BsChevronLeft
            className="text-gray-600 -translate-x-[2px]"
            size={32}
          />
        ) : null}
        {right ? (
          <BsChevronRight
            className="text-gray-600 translate-x-[2px]"
            size={32}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SliderArrow;
