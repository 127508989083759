import { createContext, useRef, useState } from "react";
import SearchBar from "components/SearchBar";
import OdooMarginTableView from "./OdooMarginTableView";
import { DateInput, Input, Select } from "components/Form";
import { useSearchParams } from "react-router-dom";
import CharlesButton from "components/charles/base";
import moment from "moment";
import { BsFilterCircle, BsFilterCircleFill } from "react-icons/bs";

export const OdooMarginContext = createContext();

const OdooMarginPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const startDate =
    searchParams.get("start") ||
    moment(new Date(new Date().setMonth(new Date().getMonth() - 1))).format(
      "YYYY-MM-DD",
    );
  const endDate =
    searchParams.get("end") || moment(new Date()).format("YYYY-MM-DD");
  const filterTotal = searchParams.get("filterTotal") || "NOT_0_TOTAL";
  const excludeCustomer = searchParams.get("excludeCustomer") || "";
  const searchText = searchParams.get("searchText") || "";

  const ref = useRef();

  const [showFilters, setShowFilters] = useState(false);

  const params = Object.fromEntries(searchParams.entries());

  return (
    <div className="flex flex-col overflow-auto p-6 pt-4 h-full space-y-2">
      <div className="flex items-center space-x-3 divide-x divide-gray-300">
        <div className="flex items-center space-x-3 flex-1">
          <label htmlFor="">From</label>
          <DateInput
            value={startDate}
            onChange={(e) =>
              setSearchParams({ ...params, start: e.target.value })
            }
          />
          <label htmlFor="">to</label>
          <DateInput
            value={endDate}
            onChange={(e) =>
              setSearchParams({ ...params, end: e.target.value })
            }
          />

          <Select
            value={filterTotal}
            onChange={(e) =>
              setSearchParams({ ...params, filterTotal: e.target.value })
            }
          >
            <option value="ALL">All</option>
            <option value="NOT_0_TOTAL">Not 0</option>
          </Select>

          <div className="flex-1">
            <SearchBar
              value={searchText}
              onChange={(text) =>
                setSearchParams({ ...params, searchText: text })
              }
              placeholder="Search by invoice number, ref or customer name."
            />
          </div>

          <CharlesButton onClick={() => setShowFilters((prev) => !prev)}>
            {excludeCustomer ? <BsFilterCircleFill /> : <BsFilterCircle />}
          </CharlesButton>
        </div>

        <div className="pl-3">
          <CharlesButton
            onClick={() => {
              if (ref.current) ref.current.download();
            }}
          >
            Download
          </CharlesButton>
        </div>
      </div>

      <div
        className={` transition-all duration-300 flex items-center
            ${showFilters ? "visible h-8" : " invisible h-0 opacity-0"}
        `}
      >
        <label className="pr-3">Exclude Customer:</label>
        <Input
          className="w-full"
          placeholder="Input the customer name you want to exclude, for example Waboba Inc."
          value={excludeCustomer}
          onChange={(e) =>
            setSearchParams({ ...params, excludeCustomer: e.target.value })
          }
        />
      </div>

      <div className="relative flex-1 card p-0 overflow-auto">
        <OdooMarginTableView
          ref={ref}
          searchText={searchText}
          startDate={startDate}
          endDate={endDate}
          filterTotal={filterTotal}
          excludeCustomer={excludeCustomer}
        />
      </div>

      <div className="mt-4 opacity-70 mx-1">
        This page shows the margin of each invoice. The margin is calculated as
        follows: 1. Total credit for account starts with 3 - total debit for
        accounts starts with 4051 or 4054 / total credit for account starts with
        3 * 100. 2. If the total credit for account starts with 3 is 0, the
        margin is 0.
      </div>
    </div>
  );
};

export default OdooMarginPage;
